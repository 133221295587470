.resume-full-screen-icon-container {
    display: flex;
    -webkit-box-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    align-items: center;
    width: 60px;
    height: 60px;
    border-radius: 28px;
    background-color: rgb(38, 160, 244);
    color: rgb(255, 255, 255);
}

.resume-full-screen-main {
    position: relative;
    z-index: 1;
    zoom: 0.6;
    transform: scale(0.992);
}

.resume-full-screen-main:hover .resume-full-screen-icon-outer {
    display: flex;
}

.resume-full-screen-icon-outer {
    cursor: pointer;
    position: absolute;
    width: 100%;
    min-height: 50%;
    display: none;
    -webkit-box-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    align-items: center;
    opacity: 1;
    top: 5%;
    z-index: 1;
}

.subsection-container {
    align-items: flex-start;
    width: 100%;
    margin: 0px auto;
    padding-bottom: 0px;
}

.dZcjzV {
    flex: 0 0 auto;
    padding: 0px 15px 0px 0px;
    display: flex;
    -webkit-box-pack: justify;
    justify-content: space-between;
    -webkit-box-align: center;
    align-items: center;
    margin-bottom: 12px;
}

.feVKyO {
    color: rgb(30, 32, 33);
    display: inline-block;
    font-size: 20px;
    line-height: 32px;
    font-weight: 600;
}

.gJaquO {
    color: rgb(150, 155, 158);
    display: inline-block;
    font-size: 16px;
    line-height: 24px;
    font-weight: 500;
}

.gQiRPX {
    display: flex;
    -webkit-box-pack: justify;
    justify-content: space-between;
    -webkit-box-align: center;
    align-items: center;
}

.fFIUJl {
    padding: 0px;
    -webkit-box-pack: justify;
    justify-content: space-between;
    -webkit-box-align: center;
    align-items: center;
    margin-bottom: 16px;
    overflow: hidden;
    display: block;
}

.dJqmzX {
    color: rgb(0, 0, 0);
    letter-spacing: 0.5px;
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    width: 100%;
    height: 28px;
    font-size: 20px;
    font-weight: 500;
    line-height: 28px;
}

.hfzZao {
    min-width: 60px;
}

.czFa-Df {
    overflow: hidden;
    padding-left: 3px;
    max-height: 28px;
}

.hMpiDu {
    color: rgb(158, 157, 157);
    height: 16px;
    width: 16px;
    margin-left: 5px;
}

.gBqHRE {
    margin-bottom: 12px;
}

.cards-main {
    overflow: visible;
    flex: 0 1 auto;
    width: 100%;
    margin: 0px auto;
    padding: 0px;
    box-sizing: border-box;
}

.sections-cards {
    box-sizing: border-box;
}

.cards-list {
    margin-bottom: 16px;
}

.cards-list {
    background: transparent;
    height: 60px;
    cursor: pointer;
    border-radius: 4px;
    box-shadow: rgb(231 235 238) 0px 3px 8px 0px, rgb(181 186 189) 0px 1px 4px 0px;
    display: block;
    transition: all 0.2s ease 0s;
}

.card-wrapper {
    width: 100%;
    height: 60px;
    display: flex;
    padding: 8px 20px;
    box-shadow: none;
    box-sizing: border-box;
    align-items: center;
    border-radius: 4px;
    justify-content: center;
    background-color: #f8f9fa;
}

.section-card-content {
    padding-left: 10px;
    display: flex;
    -webkit-box-pack: center;
    justify-content: center;
    align-items: flex-start;
    flex-direction: column;
    flex: 1 1 auto;
    overflow: hidden;
    width: 100%;
}

.MuiCardHeader-root {
    width: 100%;
    padding: 0;
}

.MuiCardHeader-content {
    flex: 1 1 auto;
    width: 100%;
}

.text-darker {
    color: rgb(30, 32, 33);
}

.MuiCardHeader-title {
    width: 100%;
    overflow: hidden;
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    white-space: nowrap;
    text-overflow: ellipsis;
}

.section-card-content>.MuiCardContent-root:last-child {
    padding-bottom: 0;
}

.section-card-content>.MuiCardContent-root {
    color: #6d7275;
    width: 100%;
    padding: 0;
    overflow: hidden;
    font-size: 14px;
    line-height: 20px;
    white-space: nowrap;
    text-overflow: ellipsis;
}

.edit-icon {
    display: flex;
    margin-left: 15px;
}

.MuiSvgIcon-root {
    color: #6D7275;
    width: 20px;
    margin: 0px 12px;
}

.card-add-section {
    display: flex;
    box-sizing: border-box;
    -webkit-box-align: center;
    align-items: center;
    padding: 8px 14px;
    border-radius: 4px;
    min-height: 60px;
    cursor: pointer;
    transition: all 0.2s ease-in-out 0s;
    box-shadow: none;
    background-color: transparent;
    border: 2px dashed rgb(183, 220, 250);
}

.icon-container {
    margin: 0px;
    padding: 0px;
    position: relative;
}

.text-section {
    min-width: 0px;
    flex: 1 1 0%;
}

.text {
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.15px;
    color: rgb(38, 160, 244);
    max-width: 100%;
    padding: 0px 12px;
    margin: 0px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    font-weight: 600;
}

.ability-level {
    font-family: Roboto, sans-serif;
    height: 100px;
    display: flex;
    -webkit-box-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    align-items: center;
    flex-direction: column;
    border-radius: 4px;
    background: rgb(240, 243, 245);
    border: none;
    cursor: pointer;
}

.ability-level.active {
    background: #daeffd;
}

.selectable-section:hover {
    cursor: pointer;
    background-color: rgba(109, 114, 117, .1);
    outline: 2px solid #0c87d4;
}

/* 2 March 2023 */
.account-dropdown .dropdown-menu.show {
    width: 225px !important;
}

.account-dropdown .dropdown-item {
    text-decoration: none !important;
    padding: 0.4rem 1rem;
    font-weight: 500 !important;
}

.account-dropdown .dropdown-item:hover {
    background-color: #e9ecef !important;
}

.account-dropdown .dropdown-item:focus {
    color: #212529 !important;
}

.settings-menu-list {
    padding-left: 22px;
    list-style: none;
}

.settings-menu-list .settings-menu-item {
    padding: 0.5rem 1rem;
    color: #212529 !important;
    border-left: 4px solid #dee2e6;
    font-size: 16px;
    display: block;
    width: 100%;
    clear: both;
    font-weight: 400;
    white-space: nowrap;
    background-color: transparent;
}


.my-container-slider {
    max-width: 700px;
    height: 250px;
    margin-top: 40px auto 0;
    margin-left: 40px auto 0;
    margin-right: 40px auto 0;
    margin-bottom: 10px auto 0;
    position: relative;
    overflow: hidden;
}

@media screen and (max-width: 700px) {
    .my-container-slider {
        margin: 40px 10px 0;
    }
}

.my-container-dot-slider {
    position: relative;
    height: 50px;
}

.my-slide {
    width: 100%;
    height: 100%;
    position: absolute;
    opacity: 0;
    transition: opacity ease-in-out 0.4s;
}

.active-anim {
    opacity: 1;
}

.my-container-dots {
    position: absolute;
    bottom: 10px;
    align-items: center;
    left: 50%;
    transform: translateX(-50%);
    display: flex;
}

.dot {
    width: 15px;
    height: 15px;
    border-radius: 50%;
    border: 1px solid #969191;
    margin: 0 5px;
    background: #f1f1f1;
    cursor: pointer;
}

.arrow {

    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}

.arrow img {
    width: 15px;
    height: 15px;
}

.dot.active {
    background: #969191;
}

.margin-right-6 {
    margin-right: 6px;
}

.inverted-comma1 {
    margin-bottom: 5px;
    margin-right: 6px;
}

.inverted-comma2 {
    margin-bottom: 5px;
    margin-left: 6px;
}


.fw-italic {
    font-style: italic;
}

.light-gray {
    color: #969191;
}

.margin15 {
    margin-right: 15px;
    margin-left: 15px;
}

.marleft110 {
    margin-left: 111px;

}



.w-15 {
    width: 15%;
}

.btn-plan {
    padding: 10px 40px;
    background: #093B43;
}

.btn-plan:hover {
    background: #0b5b68;
}

.btn-flyer {
    padding: 10px 40px;
    background: #810127;
}

.btn-flyer:hover {
    background: #c42051;
}

.adminpage.disabled {
    pointer-events: none;
}



.pb-disable {
    padding: 4px 8px;
    background-color: red;
}

.pb-disable-active {
    padding: 4px 8px;
}

.pb-disable-end {
    padding: 4px 8px;
    background-color: red;
    border-color: 0.12em solid rgba(223, 0, 0, 43);
}

.width-35 {
    width: 35%;
}
