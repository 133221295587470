/* css style job assment page is sulman */

.assessment-list {
    padding-left: 1.5em;
    margin-left: 0;
}

.jobActivity.form-check-input[type=radio] {
    border-radius: 0% !important;
}

.jobActivity.form-check-input {
    width: 70px !important;
    height: 35px !important;
}

.jobActivity.form-check-input {
    content: url(../public/images/grayCheck.png);
    background-color: #dddddd !important;
    border-color: #c1c1c1 !important;
    border-width: 2px !important;
    border-style: solid !important;
    background-image: url("data:image/svg+xml;charset=utf-8,PD94bWwgdmVyc2lvbj0iMS4wIiBzdGFuZGFsb25lPSJubyI/Pgo8IURPQ1RZUEUgc3ZnIFBVQkxJQyAiLS8vVzNDLy9EVEQgU1ZHIDIwMDEwOTA0Ly9FTiIKICJodHRwOi8vd3d3LnczLm9yZy9UUi8yMDAxL1JFQy1TVkctMjAwMTA5MDQvRFREL3N2ZzEwLmR0ZCI+CjxzdmcgdmVyc2lvbj0iMS4wIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciCiB3aWR0aD0iNjAuMDAwMDAwcHQiIGhlaWdodD0iMzAuMDAwMDAwcHQiIHZpZXdCb3g9IjAgMCA2MC4wMDAwMDAgMzAuMDAwMDAwIgogcHJlc2VydmVBc3BlY3RSYXRpbz0ieE1pZFlNaWQgbWVldCI+Cgo8ZyB0cmFuc2Zvcm09InRyYW5zbGF0ZSgwLjAwMDAwMCwzMC4wMDAwMDApIHNjYWxlKDAuMTAwMDAwLC0wLjEwMDAwMCkiCmZpbGw9IiMwMDAwMDAiIHN0cm9rZT0ibm9uZSI+CjwvZz4KPC9zdmc+Cg==") !important;

}

.jobActivity.form-check-input:checked {
    content: url(../public/images/whiteCheck.png);
    background-color: #0d6efd !important;
    border-color: #0d6efd !important;
    border-width: 2px !important;
    border-style: solid !important;
    background-image: url("data:image/svg+xml;charset=utf-8,PD94bWwgdmVyc2lvbj0iMS4wIiBzdGFuZGFsb25lPSJubyI/Pgo8IURPQ1RZUEUgc3ZnIFBVQkxJQyAiLS8vVzNDLy9EVEQgU1ZHIDIwMDEwOTA0Ly9FTiIKICJodHRwOi8vd3d3LnczLm9yZy9UUi8yMDAxL1JFQy1TVkctMjAwMTA5MDQvRFREL3N2ZzEwLmR0ZCI+CjxzdmcgdmVyc2lvbj0iMS4wIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciCiB3aWR0aD0iMTI4LjAwMDAwMHB0IiBoZWlnaHQ9IjEyOC4wMDAwMDBwdCIgdmlld0JveD0iMCAwIDEyOC4wMDAwMDAgMTI4LjAwMDAwMCIKIHByZXNlcnZlQXNwZWN0UmF0aW89InhNaWRZTWlkIG1lZXQiPgoKPGcgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoMC4wMDAwMDAsMTI4LjAwMDAwMCkgc2NhbGUoMC4xMDAwMDAsLTAuMTAwMDAwKSIKZmlsbD0iIzAwMDAwMCIgc3Ryb2tlPSJub25lIj4KPHBhdGggZD0iTTEgOTk4IGMxIC0yNjcgMSAtMjc5IDE0IC0yMTggNDkgMjI5IDI1NiA0MzYgNDg1IDQ4NSA2MSAxMyA0OSAxMwotMjE3IDE0IGwtMjgzIDEgMSAtMjgyeiIvPgo8cGF0aCBkPSJNNzgwIDEyNjQgYzM2IC03IDEwMyAtMzIgMTUwIC01NSAxNjIgLTgwIDI5NyAtMjU0IDMzNSAtNDI5IDEzIC02MQoxMyAtNDkgMTQgMjE4IGwxIDI4MiAtMjgyIC0xIGMtMjY4IC0xIC0yNzkgLTEgLTIxOCAtMTV6Ii8+CjxwYXRoIGQ9Ik01MDIgMTE2MCBjLTEzNyAtMzYgLTI2MiAtMTMxIC0zMzAgLTI1MSAtMzkgLTY5IC03MiAtMTkyIC03MiAtMjY5CjAgLTc3IDMzIC0yMDAgNzIgLTI2OSA0MSAtNzIgMTI3IC0xNTggMTk5IC0xOTkgNjkgLTM5IDE5MiAtNzIgMjY5IC03MiA3NyAwCjIwMCAzMyAyNjkgNzIgNzIgNDEgMTU4IDEyNyAxOTkgMTk5IDM5IDY5IDcyIDE5MiA3MiAyNjkgMCA3NyAtMzMgMjAwIC03MgoyNjkgLTQxIDcyIC0xMjcgMTU4IC0xOTkgMTk5IC03MSA0MCAtMTkzIDcyIC0yNzMgNzEgLTM0IDAgLTk0IC05IC0xMzQgLTE5egptNDE4IC0zMjAgYzExIC0xMSAyMCAtMjggMjAgLTM3IDAgLTIzIC0zNjAgLTM4MyAtMzg0IC0zODMgLTIxIDAgLTIwMyAxNzMKLTIxMiAyMDIgLTcgMjQgMjIgNTggNTAgNTggMTIgMCA0OCAtMjggODggLTY3IGw2OCAtNjcgMTU4IDE1NyBjODcgODYgMTY2CjE1NyAxNzUgMTU3IDkgMCAyNiAtOSAzNyAtMjB6Ii8+CjxwYXRoIGQ9Ik0xIDI4MyBsLTEgLTI4MyAyODMgMSBjMjY2IDEgMjc4IDEgMjE4IDE0IC0zNSA4IC0xMDMgMzMgLTE1MCA1NwotMTY1IDgxIC0yOTcgMjUwIC0zMzUgNDI4IC0xNCA2MSAtMTQgNTAgLTE1IC0yMTd6Ii8+CjxwYXRoIGQ9Ik0xMjYzIDQ5OCBjLTcgLTM1IC0zMSAtMTAxIC01NCAtMTQ4IC04MSAtMTYzIC0yNTIgLTI5NiAtNDI5IC0zMzQKLTYxIC0xNCAtNTAgLTE0IDIxOCAtMTUgbDI4MiAtMSAwIDI4MCBjMCAxNTQgLTEgMjgwIC0zIDI4MCAtMSAwIC04IC0yOCAtMTQKLTYyeiIvPgo8L2c+Cjwvc3ZnPgo=") !important;

}

.share-form {
    background-color: #bfd0df !important;
    padding: 15px !important;
    margin: 0 0 5px !important;
}

.CustomCountrySelectBg .optionCOntainer,
.CustomCountrySelectBg .css-tlfecz-indicatorContainer {
    background-color: #dcd9d9 !important;

}

.CustomCountrySelectBg .css-tlfecz-indicatorContainer {
    color: #000 !important
}

button:disabled {
    opacity: .5;
    pointer-events: none;
}

.align-self-baseline {
    align-self: baseline;
}

.ms-11 {
    margin-left: -11px;

}

.web-icon {
    background-color: #ccc;
    color: #000 !important;
}

.fullLoader {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(175 172 172, .47);
    z-index: 9999999;
}

.LoaderWrapper {
    position: fixed;
    z-index: 9999999999;
    background-color: #333333ab;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    justify-content: center;
    align-items: center;
}


.loader {
    transform: rotateZ(45deg);
    perspective: 1000px;
    border-radius: 50%;
    width: 100px;
    height: 100px;
    color: #fff;
}

.loader:before,
.loader:after {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: inherit;
    height: inherit;
    border-radius: 50%;
    transform: rotateX(70deg);
    animation: 1s spin linear infinite;
}

.loader:after {
    color: #0096ff;
    transform: rotateY(70deg);
    animation-delay: .4s;
}

@keyframes rotate {
    0% {
        transform: translate(-50%, -50%) rotateZ(0deg);
    }

    100% {
        transform: translate(-50%, -50%) rotateZ(360deg);
    }
}

@keyframes rotateccw {
    0% {
        transform: translate(-50%, -50%) rotate(0deg);
    }

    100% {
        transform: translate(-50%, -50%) rotate(-360deg);
    }
}

@keyframes spin {

    0%,
    100% {
        box-shadow: .2em 0px 0 0px currentcolor;
    }

    12% {
        box-shadow: .2em .2em 0 0 currentcolor;
    }

    25% {
        box-shadow: 0 .2em 0 0px currentcolor;
    }

    37% {
        box-shadow: -.2em .2em 0 0 currentcolor;
    }

    50% {
        box-shadow: -.2em 0 0 0 currentcolor;
    }

    62% {
        box-shadow: -.2em -.2em 0 0 currentcolor;
    }

    75% {
        box-shadow: 0px -.2em 0 0 currentcolor;
    }

    87% {
        box-shadow: .2em -.2em 0 0 currentcolor;
    }
}